import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginCard from '../components/LoginCard'
import SignUp from '../components/SignUp'
import ChangePassword from '../components/ChangePassword'
import ForgotPassword from '../components/ForgotPassword'
import Welcome from '../components/Welcome'
import FarmersMagazine from '../components/FarmersMagazine'
import FarmersForm from '../components/FarmersForm'
import Farmers from '../components/Farmers'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'LoginCard',
    component: LoginCard
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/magazine',
    name: 'FarmersMagazine',
    component: FarmersMagazine
  },
  {
    path: '/form',
    name: 'FarmersForm',
    component: FarmersForm
  },
  {
    path: '/farmers',
    name: 'Farmers',
    component: Farmers
  },

]

const router = new VueRouter({
  routes
})

export default router
