<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="12"  >
        <div style="margin-top:62px">
          <v-img
              :src="images"
              max-height="800"
              contain
           >
        </v-img>
        </div>    
      </v-col>
    </v-row> 
  </v-container>
    

</template>

<script>


  export default {
    name: 'Home',
    data(){
      return{
        images:require('../assets/farmers13.jpg')
      }
    },
  methods:{
    login(){
      this.$router.push('/login')
    },
    signUp(){
      this.$router.push('/signup')
    }
  }
  }
</script>
