<template>
  <div>
    <div class="deep-purple lighten-1">
      <v-container v-if="TOKEN">
            <v-app-bar
        height="36"
        color="deep-purple lighten-1"
        elevation="0"
        >
                 
           <v-avatar size="90" tile class="ml-10" @click="gotoHome">
            <v-img :src="logo" contain>
            </v-img>
           </v-avatar> 
           
           <v-avatar size="100" tile class="ml-10" @click="gotoHome">
            <v-img :src="logoTwo" contain>
            </v-img>
           </v-avatar>
           <v-spacer></v-spacer>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineForm" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'"  >MAGAZINE REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersForm" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineUsers" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'" >MAGAZINE SUBSCRIBERS</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersUsers" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTERED USERS</v-btn>
           <v-spacer></v-spacer>
           <h4 class="white--text"> {{ADMIN.name}}</h4>
           <!-- <v-icon color="white" @keypress="f11">mdi-fullscreen</v-icon> -->
           <v-avatar class="mx-3"><v-img :src="profileImage" height="50" ></v-img></v-avatar>
           <!-- <v-icon color="white" class="mr-5">mdi-progress-question</v-icon> -->
           <div class="mt-10"></div>
           <v-btn small dark class="teal" elevation="0" @click="gotoLogin"> <b>LOGOUT</b> </v-btn>
           <a href="https://web.whatsapp.com/send?text=MAGAZINE REGISTRATION FORM LINK - http://bit.ly/3FgPqy8" target="_blank"  data-action="share/whatsapp/share" class="mx-5" style="text-decoration:none"><v-icon large color="green lighten-2">mdi-whatsapp </v-icon></a>

        <!-- </v-app-bar> -->
      </v-app-bar>
        </v-container>
    </div>
    <v-container class="grey lighten-4 mt-5">
      <v-layout row wrap class="ma-3">
        <v-flex xs2 md1 >
          <v-avatar min-width="90" max-width="auto"  tile >
            <v-img :src="logo" contain >
            </v-img>
           </v-avatar>
        </v-flex>
        <v-flex xs7 md10 class="mt-8">
          <span class="text-center"><h1 class="green--text">Magazine Registration Form</h1></span>
        </v-flex>
    
     <v-flex xs2 md1 >
          <v-avatar min-width="90" max-width="auto" tile >
            <v-img :src="logoTwo" contain>
            </v-img>
           </v-avatar>
        </v-flex>
      </v-layout>
  
      <form justify="center" >
        <v-row >
          <v-col cols="12" md="12" xs="12">
            Full Name<span class="red--text">*</span> :
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            label="full name"
            require
            outlined
            dense
          ></v-text-field>
          Address Line 1 <span class="red--text">*</span> :
          <v-text-field
            v-model="address"
            label="address"
            require
            outlined
            dense
          ></v-text-field>
          Street Name <span class="red--text">*</span> :
          <v-text-field
            v-model="street"
            label="street"
            require
            outlined
            dense
          ></v-text-field>
         City<span class="red--text">*</span> :
          <v-text-field
            v-model="city"
            label="city"
            require
            outlined
            dense
          ></v-text-field>
          Pincode<span class="red--text">*</span> :
          <v-text-field
            v-model="pincode"
            label="pincode"
            require
            outlined
            dense
          ></v-text-field>
          Phone Number<span class="red--text">*</span> :
          <v-text-field
          v-model="mobile"
          outlined
          label="phone"
          dense
          >
          </v-text-field>
          WhatsApp number : 
          <v-text-field
          v-model="whatsapp"
          outlined
          label="whatsapp number"
          dense
          >
          </v-text-field>
          Email<span class="red--text">*</span> :
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="e-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            outlined
            dense
          ></v-text-field>
          District<span class="red--text">*</span> :
          <v-select
            v-model="district"
            :items="districts"
            :error-messages="selectErrors"
            label="district"
            required
            outlined
            dense
          ></v-select>
          Employee Name <span class="red--text">*</span> :
          <v-text-field
           dense
           outlined
           v-model="empName"
           label="name"
          >
          </v-text-field>
          Employee Code <span class="red--text">*</span> :
          <v-text-field
           dense
           outlined
           v-model="empCode"
           label="code"
          >
          </v-text-field>
         
          <form>
             <p class="mt-5">Subscription <span class="red--text">*</span> :</p>
              <input type="radio" id="oneYear" name="fav_language" value="1" v-model="years" required>
              <label for="oneYear" class="ml-2"> One Year ( One Year - <span class="green--text"> ₹600</span> )</label><br>
              <input type="radio" id="twoYears" name="fav_language" value="2" v-model="years" required>
              <label for="twoYears" class="ml-2"> Two Years ( Two Years - <span class="green--text"> ₹1100</span> )</label><br>
            <input type="radio" id="threeYears" name="fav_language" value="3" v-model="years" required>
            <label for="threeYears" class="ml-2"> Three Years ( Three Years - <span class="green--text"> ₹1600</span> )</label><br>
            <br>  
          </form>

          Number of Copies :{{yearsValue()}}
          <div class="mb-5">
            <input class="white pa-2" type="text" value="0" v-model="copies" disabled>
          </div>

            Amount (In Words)<span class="red--text">*</span> :
          <v-text-field
          v-model="amount"
          outlined
          label="amount"
          dense
          d
          >
          </v-text-field>
          <form>
         <p> Payment Mode<span class="red--text">*</span> :</p>
              <input type="radio" id="cheque" name="fav_language" value="Cheque" v-model="payment" required>
              <label for="cheque" class="ml-2"> Cheque</label><br>
              <input type="radio" id="dd" name="fav_language" value="D D " v-model="payment" required>
              <label for="dd" class="ml-2"> DD</label><br>
              <input type="radio" id="ppay" name="fav_language" value="UPI" v-model="payment" required>
              <label for="ppay" class="ml-2"> UPI</label><br>
              <input type="radio" id="bank" name="fav_language" value="Bank" v-model="payment" required>
              <label for="bank" class="ml-2"> Bank Details</label><br>
            <input type="radio" id="cash" name="fav_language" value="Cash" v-model="payment" required>
              <label for="bank" class="ml-2"> Cash</label><br>
            <input type="radio" id="gpay" name="fav_language" value="Others" v-model="payment" required>
              <label for="gpay" class="ml-2"> Others</label><br>
              <br>
            </form>
            <v-layout row wrap class="mt-10">
        <v-flex xs12 md12>
   
        
            <uploader class="ma-5 white" @uploadSuccess="uploadSuccess"></uploader>
         
            
            <!-- <div class="pa-5" v-if="image_url!=''"><img :src="image_url" width="100%"/></div> -->
            <!-- <div class="mb-5 ml-5"><v-btn class="green white--text"  @click="saveBanner" small>Save upload</v-btn></div> -->
        </v-flex>
    </v-layout>
          <v-btn
            :disabled="name == '' || email == '' || district == '' || address == '' || street == '' || city == '' || pincode == '' || empName == '' || empCode == '' 
            || mobile == '' || copies == '' || years == '' || amount == '' || payment == '' "
            class="mr-4 ml-2"
            @click="submit"
          >
            Submit
          </v-btn>
         
  </v-col>
  </v-row>
  </form>
    
</v-container>
  </div>
  
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import { BASE_URL } from '../config'
  import Axios from 'axios'
  import Uploader from '../components/Uploader.vue'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [validationMixin],
    
    validations: {
      name: { required,},
      email: { required, email },
      select: { required },
    },

    data: () => ({
      image_url : "",
      banners : [],
      profileImage:require('../assets/profile-pic.jpg'),
      logo : require('../assets/logo.png'),
      logoOne:require('../assets/farmes_1.png'),
      logoTwo:require('../assets/farmes_2.png'),
      name: '',
      email: '',
      district: null,
      districts : [
        'തിരുവനന്തപുരം / Thiruvanthapuram',
        'കൊല്ലം / Kollam',
        'പത്തനംതിട്ട / Pathanamthitta',
        'ആലപ്പുഴ / Alappuzha',
        'കോട്ടയം / Kottayam',
        'ഇടുക്കി / Idukki',
        'എറണാകുളം / Ernakulam',
        'തൃശ്ശൂർ / Thrissur',
        'മലപ്പുറം / Malappuram',
        'പാലക്കാട് / Palakkad',
        'വയനാട് / Wayanad',
        'കോഴിക്കോട് / Calicut',
        'കണ്ണൂർ / kannur',
        'കാസർകോട് / kasargod'
      ],
      address:"",
      street:"",
      city:"",
      pincode:"",
      empName:"",
      empCode:"",
      mobile:"",
      whatsapp:"",
      copies:"",
      // date:"",
      // dateTo:"",
      menu: false,
      menuTo: false,
      // online:"",
      years:"",
      amount:"",
      payment:"",
      // paymentDetails:"",
      code:"",
      distcode:"",
      image_url:"",
      preview: null,
      image: null,
      preview_list: [],
      image_list: []

    }),
    components : {
        Uploader,

    },
    computed: {
      ...mapGetters(['TOKEN','ADMIN']),
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Item is required')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    },

    methods: {
      uploadSuccess(value){
            this.image_url = value   
        },
      yearsValue(){
        this.copies = this.years * 12
        if(this.years == 1){
         this.amount = "Six hundred rupees"
        }
        else if(this.years == 2){
          this.amount = "One thousand and one hundred rupees"
         }
         else if(this.years == 3){
          this.amount = "One thousand and six hundred rupees"
         }
         
        
      },
    //     previewImage: function(event) {
    //   var input = event.target;
    //   if (input.files) {
    //     var reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.preview = e.target.result;
    //     }
    //     this.image=input.files[0];
    //     reader.readAsDataURL(input.files[0]);
    //   }
    // },
       gotoLogin(){
            this.$router.push('/')
        },
        magazineForm(){
          this.$router.push('/magazine')
        },
        farmersForm(){
         this.$router.push('/form')
        },
        magazineUsers(){
          this.$router.push('/welcome')
        },
        farmersUsers(){
           this.$router.push('/farmers')
        },
     async submit (){
        let now = new Date();
        let fromDate = new Date(now.getFullYear(), now.getMonth()+1,1).toDateString().split(' ').slice(1).join(' ')
        let toDate = new Date(now.getFullYear()+ Number(this.years), now.getMonth()+1,0).toDateString().split(' ').slice(1).join(' ')
        if(this.district == 'തിരുവനന്തപുരം / Thiruvanthapuram'){
          this.code = "TVM"
          this.distcode = "01"}
        else if(this.district == 'കൊല്ലം / Kollam'){
        this.code = "KLM"
        this.distcode = "02"}
        else if(this.district == 'പത്തനംതിട്ട / Pathanamthitta'){
          this.code = "PTM"
          this.distcode = "03"}
        else if(this.district == 'ആലപ്പുഴ / Alappuzha'){
            this.code = "ALP"
            this.distcode = "04"}
        else if(this.district == 'കോട്ടയം / Kottayam'){
          this.code = "KTM"
          this.distcode = "05"}
        else if(this.district == 'ഇടുക്കി / Idukki'){
          this.code = "IDK"
          this.distcode = "06"}
        else if(this.district == 'എറണാകുളം / Ernakulam'){
          this.code = "EKM"
          this.distcode = "07"}
        else if(this.district == 'തൃശ്ശൂർ / Thrissur'){
          this.code = "TSR"
          this.distcode = "08"}
        else if(this.district == 'മലപ്പുറം / Malappuram'){
          this.code = "MLP"
          this.distcode = "09"}
        else if(this.district == 'പാലക്കാട് / Palakkad'){
          this.code = "PKD"
          this.distcode = "10"}
        else if(this.district == 'വയനാട് / Wayanad'){
          this.code = "WYD"
          this.distcode = "11"}
        else if(this.district == 'കോഴിക്കോട് / Kozhikode'){
          this.code = "KZD"
          this.distcode = "12"}
        else if(this.district == 'കണ്ണൂർ / kannur'){
          this.code = "KNR"
          this.distcode = "13"}
        else{
          this.code = "KSD"
          this.distcode = "14"}
          let id = "FM" + this.code + this.distcode + "00" + parseInt(Math.random()*1000)
          
       
        let payload = {  
          id:id.toString(),
          name:this.name,
          email:this.email,
          district:this.district,
          address:this.address + " ," + this.street +" ,"+ this.city + " ," + this.pincode,
          mobile:this.mobile,
          whatsapp:this.whatsapp,
          empName:this.empName,
          empCode:this.empCode,
          copies:this.copies,
          fromDate:fromDate,
          toDate:toDate,
          status:"Not Delivered", 
          deliveredCount:0,
          years:this.years,
          amount:this.amount,
          payments:this.payment,
          // paymentDetails:this.paymentDetails,
          image:this.image_url
        }
        let url = BASE_URL + "/magazine"
        let {data} = await Axios.post(url,payload)
        this.$toasted.success("Magazine Registered Successfully")
        // this.$router.go('')
      },
      gotoLogin(){
        this.$router.push('/login')
      },
      gotoHome(){
        this.$router.push('/welcome')
      }

    },
  }
</script>

<style>

</style>