<template>
    <v-layout row wrap>
        <v-flex class="xs12 md6 ">
            <div class="blue lighten-4 pa-5 pb-12 ma-3">
                <v-img :src="image"></v-img>
            </div>    
        </v-flex>
         <v-flex class="xs12 md6">
            <v-flex class="xs12 md6">
                <v-card height="160" elevation='0'>
                </v-card>
            </v-flex>
            
             <div class="flex pa-10 white xs12" >
                <v-container>
               <v-layout class="my-3">
                <v-flex class="xs2">
                    <v-img :src="logoOne" height="100" width="160" contain>
                   </v-img>
                </v-flex>
                <v-flex class="xs2">
                    <v-img :src="logoTwo" height="100" width="160" contain>
                  </v-img>
                </v-flex>
               </v-layout>
                  <span class=" green--text my-2" style="font-size:larger">Welcome Back!</span>
                  <p class="mb-2">Please Sign in to continue</p>
              
                 <v-row dense class="mt-10">
                    <v-col  cols="12" md="10" color="green">
                        <p>Username</p>
                        <v-text-field color="green" dense outlined autofocus v-model="email" label="Username" type="name" clearable> 
                        </v-text-field>
                     </v-col>
                 <v-col cols="12" md="10" >
                    <p>Password</p>
                     <v-text-field  color="green" dense outlined v-model="password" label="Password" type="password" @keydown.enter="login"  clearable> 
                     </v-text-field>
                  </v-col>
                    <v-col cols="12" md="10">
                    <v-btn xs12 @click="login" block large class="green white--text">Login</v-btn>
                    <!-- <v-btn xs12 @click="signUp" rounded large outlined class="green--text mt-10 pl-10 pr-10 mx-2">sign up</v-btn>     -->
                    </v-col>
                    </v-row>
                    <h4 @click="forgetPassword" class="mt-3"> <a class="black--text">Forgot password?</a></h4>
               </v-container>
             </div>
         </v-flex>
     </v-layout>
 </template>
 <script>
  import Axios from 'axios'
  import { BASE_URL } from '../config'

 export default {
     data(){
         return{
             email:"",
             password : "",
            select:null,
            image:require('../assets/farmers.jpg'),
            logoOne:require('../assets/farmes_2.png'),
            logoTwo:require('../assets/farmes_1.png')

         }
         
     },
     methods : {
        async login(e){
            //email validation method
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            e.preventDefault()
                let payload = 
                            {
                            email : this.email,
                            password : this.password,
                            }
                let url = BASE_URL + "/admin/login"
                let {data} = await Axios.post(url,payload)
                            .then((response) => {   
                                this.$toasted.error(response.data.msg)
                                if(response.data.success){
                                    console.log(response.data)
                                let payload = response.data.token
                                let details = response.data.admin
                                this.$store.dispatch('SET_ADMIN',details)
                                this.$router.push({name:'Welcome'})
                                this.$store.dispatch('SET_TOKEN',payload)
                                this.$toasted.success("LOGIN SUCCESSFULLY")
                                }
                                    
                            })    
                            .catch((err) => {    
                                console.log(err)    
                            })       
            }
            else{
                this.$toasted.error('Please enter a valid email address')
        }
    },
            // signUp(){
            //     this.$store.dispatch("SET_SIGNUP",true)
            //     this.$router.push('/signup') 
            // },
            forgetPassword(){
                this.$router.push('/forgot-password')
            }
    }
}
 </script>
