<template >
  <v-app class="custom-style pl-10 pr-10">
        <router-view></router-view> 
  </v-app>

</template>

<script>

import LoginCard from './components/LoginCard';
import { mapGetters } from 'vuex';
import SignUp from './components/SignUp.vue';

export default {
  name: 'App',
  data(){
    return{

    }  
  },
  computed:{
    ...mapGetters(['LOGIN','SIGNUP','ADMIN'])
  },
         
  components: {
        LoginCard,SignUp
  }
};
</script>
