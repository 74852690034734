<template>
    <div>
      <div class="deep-purple lighten-1">
        <v-container v-if="TOKEN">
            <v-app-bar
        height="36"
        color="deep-purple lighten-1"
        elevation="0"
        >
         <v-avatar size="90" tile class="ml-10"  @click="gotoHome">
            <v-img :src="logo" contain>
            </v-img>
          </v-avatar> 
           <v-avatar size="80" tile class="ml-10"  @click="gotoHome">
            <v-img :src="logoOne" contain>
            </v-img>
           </v-avatar> 
           <v-spacer></v-spacer>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineForm" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'"  >MAGAZINE REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersForm" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineUsers" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'" >MAGAZINE SUBSCRIBERS</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersUsers" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTERED USERS</v-btn>
           <v-spacer></v-spacer>
           <h4 class="white--text"> {{ADMIN.name}}</h4>

           <!-- <v-icon color="white" @keypress="f11">mdi-fullscreen</v-icon> -->
           <v-avatar class="mx-3"><v-img :src="profileImage" height="50" ></v-img></v-avatar>
           <!-- <v-icon color="white" class="mr-5">mdi-progress-question</v-icon> -->
           <div class="mt-10"></div>
           <v-btn small dark class="teal" elevation="0" @click="gotoLogin"> <b>LOGOUT</b> </v-btn>
           <a href="https://web.whatsapp.com/send?text=FARMERS REGISTRATION FORM LINK - http://bit.ly/3uNXqSF" target="_blank"  data-action="share/whatsapp/share" class="mx-5" style="text-decoration:none"><v-icon large color="green lighten-2">mdi-whatsapp </v-icon></a>
           
        <!-- </v-app-bar> -->
      </v-app-bar>
        </v-container>
      </div>
      <v-container class="grey lighten-4 mt-5">
        <v-layout row wrap class="ma-3">
        <v-flex xs2 md1 >
          <v-avatar min-width="90" max-width="auto"  tile >
            <v-img :src="logo" contain >
            </v-img>
           </v-avatar>
        </v-flex>
        <v-flex xs7 md10 class="mt-8">
          <span class="text-center"><h1 class="green--text">Farmers Registration Form</h1></span>
        </v-flex>
    
     <v-flex xs2 md1 >
          <v-avatar min-width="90" max-width="auto" tile >
            <v-img :src="logoOne" contain>
            </v-img>
           </v-avatar>
        </v-flex>
      </v-layout>
  
        <form justify="center" >
          <v-row >
            <v-col cols="12" md="12" xs="12">
                പേര് / Name<span class="red--text">*</span> :
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Name"
              require
              outlined
              dense
            ></v-text-field>
            പിതാവിന്റെ/ മാതാവിന്റെ പേര് / Father’s Name/ Mother’s Name<span class="red--text">*</span> :
            <v-text-field
              v-model="parentName"
              :error-messages="nameErrors"
              label="Father’s Name/ Mother’s Name"
              require
              outlined
              dense
            ></v-text-field>
            ജനന തീയ്യതി / Date of Birth <span class="red--text">*</span> :
            <v-row align="center">
            <v-col
                class="d-flex"
                cols="4"
                sm="4"
            >
            <v-select outlined dense :items="newYears" label="Year" v-model="dobYear"></v-select>
            </v-col>
            <v-col
                class="d-flex"
                cols="4"
                sm="4"
            >
            <v-select outlined dense :items="months" label="Month" v-model="dobMonth"></v-select>
            </v-col>
            <v-col
                class="d-flex"
                cols="4"
                sm="4"
            >
            <v-select outlined dense :items="days" label="Day" v-model="dobDay"></v-select>
            </v-col>
            </v-row>
            വിലാസം / Address<span class="red--text">*</span> :
           <v-textarea
            outlined
            name="input-7-4"
            label="Address"
            :value="address"
            v-model="address"
            dense>
          </v-textarea>
          പിൻകോഡ് / Pincode <span class="red--text">*</span> :
            <v-text-field
            v-model="pincode"
            outlined
            label="Pincode"
            dense
            >
            </v-text-field>
            ഫോൺനമ്പർ, വാട്സാപ്പ്നമ്പർ / Phone Number, Whatsapp Number <span class="red--text">*</span> :
            <v-text-field
            v-model="mobile"
            outlined
            label="Phone Number / Whatsapp Number"
            dense
            >
            </v-text-field>
            വീട്നിൽക്കുന്ന വാർഡ് / ഡിവിഷൻ പേര് / നമ്പർ / Ward / Division / Number <span class="red--text">*</span> :
            <v-text-field
              :items="division"
              label="Ward / Division / Number"
              required
              outlined
              dense
            ></v-text-field>
            ജില്ല /District<span class="red--text">*</span> :
            <v-select
              v-model="district"
              :items="districts"
              :error-messages="selectErrors"
              label="District"
              required
              outlined
              dense
            ></v-select>
            പഞ്ചായത്ത് / മുനിസിപ്പാലിറ്റി / കോർപറേഷൻ/ Local Self Government Institution(Panchayat / Municipality / Corporation) <span class="red--text">*</span> :
           <br> <v-select
              v-model="ward"
              :items="ward"
              :error-messages="selectErrors"
              label="Division"
              required
              outlined
              dense
            ></v-select>

            <form>
                <p>നിലവിൽ ഏത്കൃഷിയാണ്ചെയ്യുന്നത് ? / What is currently being cultivated? <span class="red--text">*</span> :</p>
                <input type="checkbox" id="Kitchen" name="Kitchen" value="അടുക്കളത്തോട്ടം / Kitchen garden" v-model="kitchen" required>
                <label for="Kitchen" class="ml-2">അടുക്കളത്തോട്ടം / Kitchen garden</label><br>
                <input type="checkbox" id="Growbag" name="Growbag" value="ഗ്രോബാഗ്കൃഷി / Growbag cultivation" v-model="growBag" required>
                <label for="Growbag" class="ml-2">ഗ്രോബാഗ്കൃഷി / Growbag cultivation</label><br>
                <input type="checkbox" id="Plantation" name="വലിയ തോട്ടം / Plantation" value="No" v-model="plantation" required>
                <label for="Plantation" class="ml-2">വലിയ തോട്ടം / Plantation</label><br>
                <input type="checkbox" id="None" name="None" value="ഇതൊന്നുമല്ല / None of the above" v-model="noneOf" required>
                <label for="None" class="ml-2">ഇതൊന്നുമല്ല / None of the above</label><br><br>
            </form>
             
              <form>
                <p> ഫാർമേഴ്സ്സംഘടനയുടെ നേതൃസ്ഥാനത്തു പ്രവർത്തിക്കാൻ താല്പര്യല്പമുണ്ടോ?/ Are you interested in working under the leadership of the Farmers Organization? <span class="red--text">*</span> :</p>
                <input type="radio" id="yes" name="fav_language" value="Yes" v-model="farmers" required>
                <label for="yes" class="ml-2">Yes </label><br>
                <input type="radio" id="no" name="fav_language" value="No" v-model="farmers" required>
                <label for="no" class="ml-2">No</label><br><br>
            </form>

            <form>
                <p> അംഗത്വ ഫീസ്നൽകിയതാണോ? / Is the membership fee paid?  <span class="red--text">*</span> :</p>
                <input type="radio" id="yes" name="fav_language" value="Yes" v-model="paid" required>
                <label for="yes" class="ml-2">Yes </label><br>
                <input type="radio" id="no" name="fav_language" value="No" v-model="paid" required>
                <label for="no" class="ml-2">No</label><br><br>
            </form>

           <p>വ്യക്തിയെ നിർദേശിക്കുന്നആളുടെ ഫാർമേഴ്സ്ഐഡി നമ്പർ / I'd Number<span class="red--text">*</span> : </p> 
            <v-text-field
              v-model="idNumber"
              label="Id Number"
              required
              outlined
              dense
            ></v-text-field>
            <input type="checkbox" id="agree" name="agree" value="agree" v-model="agree" required>
                <label for="agree" class="ml-2">മേൽപ്പറഞ്ഞകാര്യങ്ങൾ സത്യസന്ധമാണെന്നും ഫാർമേഴ്സ്സംഘടനയുടെ
                                                നിയമായവലി അനുസരിച്ചു പ്രവർത്തിച്ചുകൊള്ളുമെന്നും ഞാൻ സമ്മതിക്കുന്നു. / <br>
                                                I agree that the above is true and will act in accordance with the rules of the Farmers Organization</label><br>
            <v-btn
            :disabled=" agree == ''"
              class="mr-4"
              @click="submit"
            >
              Submit
            </v-btn>
           
    </v-col>
    </v-row>
    </form>
      
  </v-container>
    </div>
    
  </template>
  
  <script>
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, email } from 'vuelidate/lib/validators'
    import { BASE_URL } from '../config'
    import Axios from 'axios'
import { mapGetters } from 'vuex'
  
    export default {
      mixins: [validationMixin],
      
      validations: {
        name: { required,},
        email: { required, email },
        select: { required },
      },
      
      data: () => ({
        profileImage:require('../assets/profile-pic.jpg'),
        logo : require('../assets/logo.png'),
        logoOne:require('../assets/farmes_1.png'),
        logoTwo:require('../assets/farmes_2.png'),
        name: '',
        district: null,
        districts : [
          'തിരുവനന്തപുരം / Thiruvanthapuram',
          'കൊല്ലം / Kollam',
          'പത്തനംതിട്ട / Pathanamthitta',
          'ആലപ്പുഴ / Alappuzha',
          'കോട്ടയം / Kottayam',
          'ഇടുക്കി / Idukki',
          'എറണാകുളം / Ernakulam',
          'തൃശ്ശൂർ / Thrissur',
          'മലപ്പുറം / Malappuram',
          'പാലക്കാട് / Palakkad',
          'വയനാട് / Wayanad',
          'കോഴിക്കോട് / Calicut',
          'കണ്ണൂർ / kannur',
          'കാസർകോട് / kasargod'
        ],
        months:["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"],
        days:["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"],
        dobYear:"",
        dobMonth:"",
        dobDay:"",
        parentName:"",
        address:"",
        pincode:"",
        mobile:"",
        ward:["പഞ്ചായത്ത് / Panchayat", "മുനിസിപ്പാലിറ്റി / Municipality" ,"കോർപറേഷൻ / Corporation"],
        code:"",
        distcode:"",
        division:"",
        kitchen:"",
        growBag:"",
        plantation:"",
        noneOf:"",
        farmers:"",
        idNumber:"",
        paid:"",
        agree:""
  
      }),
  
      computed: { 
        ...mapGetters(['TOKEN','ADMIN']),
        newYears () {
        const year = new Date().getFullYear()
        return (Array.from({length: year - 1900}, (value, index) => year - index))
        },
        // newMonths () {
        // const year = new Date().getMonth()
        // return (Array.from({length: year - 1900}, (value, index) => year - index))
        // },
  
        checkboxErrors () {
          const errors = []
          if (!this.$v.checkbox.$dirty) return errors
          !this.$v.checkbox.checked && errors.push('You must agree to continue!')
          return errors
        },
        selectErrors () {
          const errors = []
          if (!this.$v.select.$dirty) return errors
          !this.$v.select.required && errors.push('Item is required')
          return errors
        },
        nameErrors () {
          const errors = []
          if (!this.$v.name.$dirty) return errors
          !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
          !this.$v.name.required && errors.push('Name is required.')
          return errors
        },
        emailErrors () {
          const errors = []
          if (!this.$v.email.$dirty) return errors
          !this.$v.email.email && errors.push('Must be valid e-mail')
          !this.$v.email.required && errors.push('E-mail is required')
          return errors
        },
      },
  
      methods: {
        gotoLogin(){
            this.$router.push('/')
        },
        magazineForm(){
          this.$router.push('/magazine')
        },
        farmersForm(){
         this.$router.push('/form')
        },
        magazineUsers(){
          this.$router.push('/welcome')
        },
        farmersUsers(){
           this.$router.push('/farmers')
        },
        gotoHome(){
          this.$router.push('/welcome')
        },
       async submit (){
          let now = new Date();
          let fromDate = new Date(now.getFullYear(), now.getMonth()+1,1).toDateString().split(' ').slice(1).join(' ')
          let toDate = new Date(now.getFullYear()+ Number(this.years), now.getMonth()+1,0).toDateString().split(' ').slice(1).join(' ')
          if(this.district == 'തിരുവനന്തപുരം / Thiruvanthapuram'){
            this.code = "TVM"
            this.distcode = "01"}
          else if(this.district == 'കൊല്ലം / Kollam'){
          this.code = "KLM"
          this.distcode = "02"}
          else if(this.district == 'പത്തനംതിട്ട / Pathanamthitta'){
            this.code = "PTM"
            this.distcode = "03"}
          else if(this.district == 'ആലപ്പുഴ / Alappuzha'){
              this.code = "ALP"
              this.distcode = "04"}
          else if(this.district == 'കോട്ടയം / Kottayam'){
            this.code = "KTM"
            this.distcode = "05"}
          else if(this.district == 'ഇടുക്കി / Idukki'){
            this.code = "IDK"
            this.distcode = "06"}
          else if(this.district == 'എറണാകുളം / Ernakulam'){
            this.code = "EKM"
            this.distcode = "07"}
          else if(this.district == 'തൃശ്ശൂർ / Thrissur'){
            this.code = "TSR"
            this.distcode = "08"}
          else if(this.district == 'മലപ്പുറം / Malappuram'){
            this.code = "MLP"
            this.distcode = "09"}
          else if(this.district == 'പാലക്കാട് / Palakkad'){
            this.code = "PKD"
            this.distcode = "10"}
          else if(this.district == 'വയനാട് / Wayanad'){
            this.code = "WYD"
            this.distcode = "11"}
          else if(this.district == 'കോഴിക്കോട് / Kozhikode'){
            this.code = "KZD"
            this.distcode = "12"}
          else if(this.district == 'കണ്ണൂർ / kannur'){
            this.code = "KNR"
            this.distcode = "13"}
          else{
            this.code = "KSD"
            this.distcode = "14"}
            let id = "FM" + this.code + this.distcode + "00" + parseInt(Math.random()*1000)
            alert(id)
         if(this.kitchen == true){
            this.kitchen = "അടുക്കളത്തോട്ടം / Kitchen garden"
         }
         else{
          this.kitchen = ''
         }
         if(this.growBag == true){
          this.growBag = "ഗ്രോബാഗ്കൃഷി / Growbag cultivation"
         }
         else{
          this.growBag = ''
         }
         if(this.plantation == true){
          this.plantation = "വലിയ തോട്ടം / Plantation"
         }
         else{
          this.plantation = ''
         }
        if(this.noneOf == true){
          this.noneOf = "ഇതൊന്നുമല്ല / None of the above"
         }
         else{
          this.noneOf = ''
         }
          let payload = {  


            id:id.toString(),
            name:this.name,
            parentName:this.parentName,
            dob:this.dobDay + "-" + this.dobMonth + "-" + this.dobYear,
            district:this.district,
            address:this.address,
            phone:this.mobile,
            pincode:this.pincode,
            ward:this.ward,
            cultivated : [this.kitchen,this.growBag,this.plantation,this.noneOf],
            farmers:this.farmers,
            paid : this.paid,
            idNumber:this.idNumber
          }

          let url = BASE_URL + "/form"
          let {data} = await Axios.post(url,payload)
          this.$toasted.success("Form Registered Successfully")
          this.$router.go('')
        },
        
      },
    }
  </script>
  
  <style>
  
  </style>