<template>
    <div class=" pb-10">
    <div class="deep-purple lighten-1">
        <v-container >
            <v-app-bar
        height="36"
        color="deep-purple lighten-1"
        elevation="0"
         >
         <v-avatar size="90" tile class="ml-10">
            <v-img :src="logo" contain>
            </v-img>
           </v-avatar>
          
           <v-avatar size="100" tile class="ml-10">
            <v-img :src="logoTwo" contain>
            </v-img>
           </v-avatar> 
           <v-spacer></v-spacer>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineForm" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'" >MAGAZINE REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersForm" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineUsers" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'" >MAGAZINE SUBSCRIBERS</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersUsers" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTERED USERS</v-btn>
           <v-spacer></v-spacer>
           <h4 class="white--text"> {{ADMIN.name}}</h4>
          

           <!-- <v-icon color="white" @keypress="f11">mdi-fullscreen</v-icon> -->
           <v-avatar class="mx-3"><v-img :src="profileImage" height="50" ></v-img></v-avatar>
           <!-- <v-icon color="white" class="mr-5">mdi-progress-question</v-icon> -->
           <div class="mt-10"></div>
           <v-btn small dark class="teal" elevation="0" @click="gotoLogin"> <b>LOGOUT</b> </v-btn>
        <!-- </v-app-bar> -->
      </v-app-bar>
        </v-container>
     
    </div>
    <div>
        <template>
            <!-- <v-container> -->
                <v-card elevation="0" class="ma-2 grey lighten-2" >
                <!-- <v-card-title> -->
                    <v-row class="ma-6">
                        <v-col cols="12"
                                xs="2"
                                md="3">
                            <v-text-field
                            dense
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                            color="teal"
                            clearable
                            rounded
                            class="mt-6"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                     
                        <h2 class="ma-5 teal--text">  Total Registrations : {{items.length}}</h2> 
                      
                    <!-- </v-card-title> -->
                        <v-data-table
                        id="table"
                        :headers="headers"
                        :items="itemsWithSno"
                        :search="search"
                         dense
                        >
                    <template v-slot:item.created="{ item }">
                    <span v-if="item.created" class="blue--text">{{new Date(item.created).toLocaleString()}}</span>
                    </template>
                        <template v-slot:item.image="{ item }">
                            <v-icon
                                class="green--text"
                                 @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            </template>
                        <template v-slot:item.status="{ item }">
                        <v-chip
                          :color="getColor(item.status)"
                          dark
                        >
                          {{ item.status }}
                    </v-chip>
                   
                    </template>
                    <template v-slot:item.paymentStatus="{ item }">
                        <v-chip
                        
                          :color="getStatus(item.paymentStatus)"
                          dark
                        >
                          {{ item.paymentStatus }}
                    </v-chip>
                   
                    </template>
                    </v-data-table>
                </v-card>
                <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="600"
    >
     <v-card class="pa-3">
       <h3 class="text-center mt-3 green--text">PAYMENT DETAILS</h3>
       <v-img class="ma-5" :src="imageUrl"></v-img>
       <h4  class="text-center mt-3 orange--text"> STATUS UPDATION</h4>
        <v-card-actions>
            
        <v-row align="center" class="ma-4">
      <v-col
        class="d-flex"
        cols="6"
        sm="6"
      >
        <v-select label="payment" outlined :items="paymentArray" v-model="paymentStatus"></v-select>

      </v-col>
      <v-col
        class="d-flex"
        cols="6"
        sm="6"
      >
        <v-select label="status" outlined :items="delivered" v-model="status"></v-select>

      </v-col>
     
      <v-col
        class="d-flex"
        cols="12"
        sm="12"
      >
        <v-btn :disabled="status =='' && paymentStatus == ''" color="blue" width="200" dark @click="saveStatus">
            Save
        </v-btn>  
        <v-spacer></v-spacer>  
          <v-btn
            color="success"
            @click="closeDialogue"
          >
            close
          </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
            <!-- </v-container> -->
            <vue-excel-xlsx
        :data="items"
        :columns="headers"
        :file-name="'Farmers-Magazine'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        >
      <v-btn class="green pa-3 text-center mx-3" dark rounded> <v-icon class="mx-2">mdi-microsoft-excel</v-icon> Download In Excel</v-btn> 
      
    </vue-excel-xlsx>
    <v-btn class="grey pa-3 text-center mx-3" dark rounded @click="deliverToAll"> <v-icon class="mx-2">mdi-truck-delivery</v-icon>Deliver to All</v-btn> 
        </template>
    </div>
</div>
</template>

<script>
import  Axios  from 'axios'
import { mapGetters } from 'vuex'
import { BASE_URL } from '../config'


export default {
    data(){
        return{
            drawer: false,
            group: null,
            viewImage:false,
            imageUrl:"",
            paymentImage:"",
            profileImage:require('../assets/profile-pic.jpg'),
            logo:require('../assets/spc_logo.png'),
            logoOne:require('../assets/farmes_1.png'),
            logoTwo:require('../assets/farmes_2.png'),
            search: '',
            dialog: false,
            delivered:["Delivered" , "Not Delivered"],
            paymentArray:["Verified" , "Not Verified"],
            paymentStatus:"",
            count:"",
            status:"",
            id:"",
            headers: [
                    { text:'#',align: 'start',value:'sno',class:'teal white--text',label:"#",field:"sno"},
                    { text:'REG ID', value: 'applicationid',class:'teal white--text',label:"Registration Id",field:"applicationid"},
                    { text:'NAME', value: 'name',class:'teal white--text',label:"Name",field:"name"},
                    { text:'ADDRESS', value: 'address',class:'teal white--text',label:"Address",field:"address" },
                    { text:'PHONE', value: 'phone',class:'teal white--text',label:"Phone",field:"phone" },
                    { text:'PAYMENT STATUS', value: 'paymentStatus',class:'teal black--text',label:"Payment Status",field:"paymentStatus" },
                    { text:'EMAIL', value: 'email',class:'teal white--text',label:"Email_id",field:"email" },
                    { text:'DELIVERY STATUS', value: 'status',class:'teal black--text',label:"Status",field:"status"},
                    // { text:'DELIVERED COUNT', value: 'deliveredCount',class:'teal white--text',label:"DELIVERED_COUNT",field:"deliveredCount" },
                    { text:'DISTRICT', value: 'district',class:'teal white--text',label:"District",field:"district" },
                    { text:'EMPLOYEE NAME', value: 'employeeName',class:'teal white--text' ,label:"Employee_Name",field:"employeeName"},
                    { text:'EMPLOYEE CODE', value: 'employeeCode',class:'teal white--text' ,label:"Employee_Code",field:"employeeName"},
                    { text:'COPIES', value: 'copies',class:'teal white--text' ,label:"Copies",field:"copies"},
                    { text:'PERIOD FROM', value: 'fromDate',class:'teal white--text',label:"Period_From",field:"fromDate" },
                    { text:'PERIOD TO ', value: 'toDate',class:'teal white--text',label:"Period_To",field:"toDate" },
                    { text:'SUBSCRIPTION (years)', value: 'years',class:'teal white--text',label:"Subscription",field:"years" },
                    { text:'AMOUNT IN WORDS', value: 'amount',class:'teal white--text',label:"Amount_In_Words",field:"amount" },
                    { text:'PAYMENT MODE', value: 'payments',class:'teal white--text',label:"Payment_Mode",field:"payments" },
                    { text:'DATE REGISTERED', value: 'created',class:'teal white--text',label:"Date_Registered",field:"created" },
                    { text:'EDIT', value: 'image',class:'teal white--text',label:"PAYMENT_DETAILS",field:"image",sortable: false, },

                 ],
                   items: [],
        }
      
    },
    async mounted(){
        let url = BASE_URL + "/magazine"
        let {data} = await Axios.get(url)
        this.items = data
    },
    methods:{
     
        gotoLogin(){
            this.$router.push('/')
        },
        editItem(item){
            this.dialog = true
            this.imageUrl = item.image
            this.id =item._id;
        },
        closeDialogue(){
            this.dialog = false
        },
        async saveStatus(){
            let payload = {
                id : this.id,
                paymentStatus : this.paymentStatus,
                status : this.status
            }
            let url = BASE_URL + "/magazine/edit"
            let {data} = await Axios.post(url,payload)
            this.dialog = false
            this.$router.go('')
        },
        magazineForm(){
          this.$router.push('/magazine')
        },
        farmersForm(){
         this.$router.push('/form')
        },
        magazineUsers(){
          this.$router.push('/welcome')
        },
        farmersUsers(){
           this.$router.push('/farmers')
        },
        getColor (calories) {
        if (calories == "Not Delivered") return 'red'
        else if (calories == "Delivered") return 'green'
        else return 'white'
      },
      getStatus (calories) {
        if (calories == "Not Verified") return 'red'
        else if (calories == "Verified") return 'cyan'
        
      },
     async deliverToAll(){
        let url = BASE_URL + "/magazine/delivery"
        let {data} = await Axios.get(url)
        this.$toasted.error("Delivery Status Updated")
      },
    },
   computed: {
    itemsWithSno() {
      return this.items.map((d, index) => ({...d, sno: index + 1 }))
    },
        ...mapGetters(['TOKEN','ADMIN'])
    }
   
}
</script>

<style> 

</style>