<template>
  <v-layout row wrap style="height:100%">
         <v-flex class="xs12 md6 offset-md-3">
             <div class="flex pa-10  white elevatin-2 xs12">
              <div class="vertical-spacing"></div>
               <div class="text-center">
                 <img src="../assets/logo.png" width="150px" />
                  <h2 class=" green--text text--darken-4">SIGN UP</h2>
               </div>
                 <v-container>
              <v-row dense >
                <v-col  cols="12" md="12">
                  <v-text-field color="green"  outlined autofocus v-model="username" class="mt-2 " label="Name" clearable > 
                </v-text-field>
               </v-col>
                <v-col  cols="12" md="12">
                  <v-text-field color="green" outlined v-model="phone" class="mt-2" label="Phone" clearable> 
                </v-text-field>
               </v-col>
               <v-col  cols="12" md="12">
                  <v-select color="green" required single-line outlined v-model="role" :items="items" class="mt-2" label="Role"> 
                  </v-select>
               </v-col>
                 <v-col  cols="12" md="12">
                  <v-text-field color="green"  outlined v-model="email" class="mt-2" label="email" type="email" clearable> 
                </v-text-field>
               </v-col>
               <v-col cols="12" md="12">
                  <v-text-field color="green"  outlined v-model="password" class="mt-2" label="Password" type="password" clearable> 
                  </v-text-field>
               </v-col>
               <v-checkbox
               label=" I agree with the terms and conditions"
               v-model="checkbox"
               value:submit
               color="success"
               >
               
               </v-checkbox>
              <v-col cols="12" md="12" align="center">
               <v-btn xs12 @click.prevent="signUp" :disabled="checkbox == ''" rounded large class="green white--text px-12 mt-10 mx-2">Sign Up</v-btn>
               <v-btn xs12 @click="login"  rounded outlined large class="green--text mt-10 pl-10 pr-10 mx-2">Login</v-btn>    
              </v-col>
              </v-row>
            </v-container>
             </div>
         </v-flex>
     </v-layout>
</template>

<script>
import Axios from 'axios'
import { BASE_URL } from '../config'

export default {
    data(){
         return{
             username:"",
             phone:"",
             email:"",
             password : "",
             district:"",
             role:"",
             checkbox:"",
             items: [ "Admin",
                      "Magazine",
                      "Farmers"
                    ],
             msg:[],

         }
     },
     methods : {
                  validateEmail() {
                      
                      console.log('LIJO')
            },
        login(){
             this.$store.dispatch('SET_SIGNUP',false)
             this.$router.push('/login')
         },

        async signUp(){
          //email validation method
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            if(this.checkbox == true) {
                            let payload =
                                    { username : this.username,
                                      phone : this.phone,
                                      role : this.role,
                                      email : this.email,
                                      password : this.password,
                                    }
                                let url = BASE_URL + "/admin"
                                let {data} = await Axios.post(url,payload)
                                      // .then((response) => {
                                      //     console.log(response);
                                //   if(response.data.msg === "USER CREATED SUCCESSFULLY"){
                                //     this.$toasted.success(response.data.msg )
                                   
                                //     this.$router.push('/welcome')
                                //   }
                                // else this.$toasted.error(response.data.msg )
                            // })
                            // .catch((err) => {    
                            //     console.log(err)    
                            // })  
                       
                         }
                      } 
                      else {
                             this.$toasted.error('Please enter a valid email address');
                      }
            
         }  
     }
}
</script>

