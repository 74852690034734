<template>
    <v-layout row wrap>
        <v-flex class="xs12 md6 ">
            <div class="blue lighten-4 pa-5 pb-12 ma-3">
                <v-img :src="image"></v-img>
            </div>    
        </v-flex>
         <v-flex class="xs12 md6">
            <v-flex class="xs12 md6">
                <v-card height="160" elevation='0'>
                </v-card>
            </v-flex>
            
             <div class="flex pa-10 white xs12 " >
                <v-container>
               <div>
                 <v-img :src="logo" height="80" width="100" contain>
                 </v-img>
               </div>
                  <h2 class=" green--text my-2" style="font-size:larger" v-if="!showPassword">Forget Password</h2>
                  <h4 class="mb-2" v-if="!showPassword">Please enter your registered email address or username</h4>
                  <h4 class="mb-2" v-if="showPassword">Please enter your new password</h4>
              
                 <v-row dense class="mt-10" v-if="!showPassword">
                    <v-col  cols="12" md="10" color="green">
                        <p>Email</p>
                        <v-text-field color="green" dense outlined autofocus v-model="email" label="Email" type="name" clearable> 
                        </v-text-field>
                     </v-col>
                    <v-col cols="12" md="10">
                    <v-btn xs12 @click="login" block large class="green white--text">Submit</v-btn>
                    </v-col>
                    </v-row>
                    <v-row dense class="mt-10" v-if="showPassword">
                        <v-col  cols="12" md="10" color="green">
                        <p>New Password</p>
                        <v-text-field color="green" dense outlined autofocus v-model="newPassword" label="New Password" type="password" clearable> 
                        </v-text-field>
                     </v-col>
                    
                    <v-col  cols="12" md="10" color="green">
                        <p>Confirm Password</p>
                        <v-text-field color="green" dense outlined autofocus v-model="confirmPassword" label="Confirm Password" type="password" clearable> 
                        </v-text-field>
                     </v-col>
                    <v-col cols="12" md="10">
                    <v-btn xs12 @click="reset" block large class="green white--text">Reset Password</v-btn>
                    </v-col>
                    </v-row>
                    <div class="main-signin-footer mt-10">
			        <p>Already Registered? <a style="color:black;text-decoration:none" @click="gotoLogin()"> <b>Login</b> </a></p>
			        </div>
               </v-container>
             </div>
         </v-flex>
     </v-layout>
 </template>
 <script>
  import Axios from 'axios'
  import { BASE_URL } from '../config'

 export default {
     data(){
         return{
             email:"",
             newPassword : "",
             confirmPassword : "",
             select : null,
             showPassword : false,
             image:require('../assets/farmers1.jpg'),
             logo:require('../assets/logo.png')
         }
         
     },
     methods : {
        async login(e){
            //email validation method
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            e.preventDefault()
                let payload = {email : this.email}
                let url = BASE_URL + "/check-email"
                let {data} = await Axios.post(url,payload)
                            .then((response) => {   
                               
                                if(response.data.success){
                                    this.showPassword = true 
                                    this.$toasted.success(response.data.msg)
                                }
                                else{
                                    this.$toasted.error(response.data.msg)
                                }
                            })    
                            .catch((err) => {    
                                console.log(err)    
                            })       
            }
            else{
                this.$toasted.error('Please enter a valid email address')
        }
    },
   async reset(){
        if(this.confirmPassword == this.newPassword){
            let payload= {email:this.email,password : this.newPassword}
     
            let url = BASE_URL + "/reset-password"
            let {data} = await Axios.post(url,payload).then((response) => {
                if(response.data.success){ 
                    this.$toasted.success(response.data.msg)
                    this.$router.push("/")
                }
                 else{
                    this.$toasted.error(response.data.msg)
                }
            })
        }
        else{
            this.$toasted.error("Password does not match")
        }
     },

            gotoLogin(){
                this.$router.push('/')
            }
    }
}
 </script>
