<template>
    <div class=" pb-10">
    <div class="deep-purple lighten-1">
        <v-container>
            <v-app-bar
        height="36"
        color="deep-purple lighten-1"
        elevation="0"
        >
        <v-avatar size="80" tile class="ml-10" @click="gotoHome">
            <v-img :src="logo" contain>
            </v-img>
        </v-avatar> 
           <v-avatar size="80" tile class="ml-10" @click="gotoHome">
            <v-img :src="logoOne" contain>
            </v-img>
            
           </v-avatar> 
           <v-spacer></v-spacer>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineForm" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'"  >MAGAZINE REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersForm" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTRATION FORM</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="magazineUsers" v-if="ADMIN.role == 'Magazine' || ADMIN.role == 'admin'" >MAGAZINE SUBSCRIBERS</v-btn>
           <v-btn class="mx-5 deep-purple lighten-3" small dark @click="farmersUsers" v-if="ADMIN.role == 'Farmers' || ADMIN.role == 'admin'" >FARMERS REGISTERED USERS</v-btn>
           <v-spacer></v-spacer>
           <h4 class="white--text"> {{ADMIN.name}}</h4>
           <!-- <v-icon color="white" @keypress="f11">mdi-fullscreen</v-icon> -->
           <v-avatar class="mx-3"><v-img :src="profileImage" height="50" ></v-img></v-avatar>
           <!-- <v-icon color="white" class="mr-5">mdi-progress-question</v-icon> -->
     
           <v-btn small dark class="teal" elevation="0" @click="gotoLogin()"> <b>LOGOUT</b> </v-btn>
        <!-- </v-app-bar> -->
      </v-app-bar>
        </v-container>
    </div>
    <div>
        <template>
            <!-- <v-container> -->
                <v-card elevation="0" class="ma-2 grey lighten-3" >
                <!-- <v-card-title> -->
                    <v-row class="ma-6">
                        <v-col cols="12"
                                xs="2"
                                md="3">
                            <v-text-field
                            class="mt-5"
                            dense
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                            color="green"
                            clearable
                            rounded
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <h2 class="ma-5 light-blue--text">  Total Registrations : {{items.length}} </h2> 
                    <!-- </v-card-title> -->
                        <v-data-table
                        id="table"
                        :headers="headers"
                        :items="itemsWithSno"
                        :search="search"

                        >
                    <template v-slot:item.created="{ item }">
                    <span v-if="item.created" class="blue--text">{{new Date(item.created).toLocaleString()}}</span>
                    </template>
                    </v-data-table>
                </v-card>
            <vue-excel-xlsx
        :data="items"
        :columns="headers"
        :file-name="'Farmers-Form'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        >
      <v-btn class="green pa-3 text-center mx-3" dark rounded> <v-icon class="mx-2">mdi-microsoft-excel</v-icon> Download In Excel</v-btn> 
    </vue-excel-xlsx>
        </template>
    </div>
</div>
</template>

<script>
import  Axios  from 'axios'
import { mapGetters } from 'vuex'
import { BASE_URL } from '../config'


export default {
    data(){
        return{
            viewImage:false,
            imageUrl:"",
            paymentImage:"",
            profileImage:require('../assets/profile-pic.jpg'),
            logoOne:require('../assets/farmes_1.png'),
            logoTwo:require('../assets/farmes_2.png'),
            logo:require('../assets/spc_logo.png'),
            search: '',
            dialog: false,
            delivered:["Delivered" , "Not Delivered"],
            count:"",
            status:"",
            id:"",
            headers: [
                    { text:'#',align: 'start',value:'sno',class:'light-blue',label:"#",field:"sno"},
                    { text:'REGISTRATION ID', value: 'applicationid',class:'light-blue',label:"Registration Id",field:"applicationid"},
                    { text: 'NAME', value: 'name',class:'light-blue',label:"Name",field:"name"},
                    { text: 'ADDRESS', value: 'address',class:'light-blue',label:"Address",field:"address" },
                    { text: 'PINCODE', value: 'pincode',class:'light-blue',label:"Pincode",field:"pincode" },
                    { text: 'MOBILE', value: 'mobile',class:'light-blue',label:"Mobile",field:"mobile" },
                    { text: 'DATE_OF_BIRTH', value: 'dob',class:'light-blue',label:"Dob",field:"dob" },
                    { text: 'CULTIVATED', value: 'cultivated',class:'light-blue',label:"Cultivated",field:"cultivated"},
                    { text: 'WARD', value: 'ward',class:'light-blue',label:"Ward",field:"ward" },
                    { text: 'DISTRICT', value: 'district',class:'light-blue',label:"District",field:"district" },
                    { text: 'INTERESTED IN LEADERSHIP', value: 'farmers',class:'light-blue' ,label:"INTERESTED IN LEADERSHIP",field:"farmers"},
                    { text: 'PAID', value: 'paid',class:'light-blue',label:"Paid",field:"paid" },
                    { text: 'ID NUMBERS', value: 'idNumber',class:'light-blue',label:"ID NUMBERS",field:"idNumber" },
                    { text: 'DATE REGISTERED', value: 'created',class:'light-blue',label:"Date_Registered",field:"created" },
                 ],
                   items: [],
        }
      
    },
    async mounted(){
        let url = BASE_URL + "/form"
        let {data} = await Axios.get(url)
        this.items = data
    },
    methods:{
        magazineForm(){
          this.$router.push('/magazine')
        },
        farmersForm(){
         this.$router.push('/form')
        },
        magazineUsers(){
          this.$router.push('/welcome')
        },
        farmersUsers(){
           this.$router.push('/farmers')
        },
        gotoLogin(){
            // alert('dsdsd')
            this.$router.push('/')
        },
        gotoHome(){
          this.$router.push('/welcome')

        }
        // editItem(item){
        //     this.dialog = true
        //     this.imageUrl = item.image
        //     this.id =item._id;
        // },
        // closeDialogue(){
        //     this.dialog = false
        // },
        // async saveStatus(){
        //     let payload = {
        //         id : this.id,
        //         deliveredCount : this.count,
        //         status : this.status
        //     }
        //     let url = BASE_URL + "/magazine/edit"
        //     let {data} = await Axios.post(url,payload)
        //     this.dialog = false
        // }
    },
   computed: {
    itemsWithSno() {
      return this.items.map((d, index) => ({...d, sno: index + 1 }))
    },
        ...mapGetters(['TOKEN','ADMIN'])
    }
   
}
</script>

<style> 

</style>